
import {computed, defineComponent, onMounted, PropType, ref} from "vue";
import {Ad} from "../store/state";
import {useStore} from "../store/store";
import {normalizeGender} from "../ts/genderHelper";
import {getAvatarUrl, getProfileUrl} from "../ts/urlHelper";
import {useToast} from "primevue/usetoast";
import ResizeObserver from "resize-observer-polyfill";

export default defineComponent({
  name: "Ad",
  props: {
    ad: {
      type: Object as PropType<Ad>,
      required: true,
    },
    isFavorites: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    //Hacky stuff required to determine if the text is overflowing or not
    const adroot = ref<HTMLElement | null>(null);
    const clientHeight = ref(adroot.value?.clientHeight);
    const scrollHeight = ref(adroot.value?.scrollHeight);
    const isExpanded = ref<boolean>(false);
    const reassignDimensions = () => {
      clientHeight.value = adroot.value?.clientHeight;
      scrollHeight.value = adroot.value?.scrollHeight;
    };
    onMounted(() => {
      reassignDimensions();
      new ResizeObserver(reassignDimensions).observe(
          (adroot.value as unknown) as Element
      );
    });
    const overflows = computed(() => {
      if (clientHeight.value != null && scrollHeight.value != null) {
        return clientHeight.value < scrollHeight.value;
      } else {
        return false;
      }
    });

    const store = useStore();
    const noInfo = "No Info";
    const toast = useToast();
    const timeOnly = computed(() => store.getOptions.value.timeOnly);
    const nightmodeActive = computed(() => store.getOptions.value.nightMode);
    const time = computed(() => {
      const t = props.ad.timestamp ? new Date(props.ad.timestamp) : new Date();
      return timeOnly.value
          ? "[" + t.toLocaleTimeString() + "]"
          : "[" + t.toLocaleTimeString() + " - " + t.toLocaleDateString() + "]";
    });
    const pushIgnore = () => {
      const detailMsg =
          props.ad.characterName + " was added to Ignores. See Options.";
      toast.add({
        severity: "info",
        summary: "Ignore added",
        detail: detailMsg,
        life: 3000,
      });
      store.pushIgnore(props.ad.characterName);
    };
    const pushFavorite = () => {
      const detailMsg = "See favorites tab.";
      toast.add({
        severity: "success",
        summary: "Added to favorites.",
        detail: detailMsg,
        life: 3000,
      });
      store.pushFavorite(props.ad);
    };

    return {
      adroot,
      overflows,
      isExpanded,
      pushIgnore,
      pushFavorite,
      dropFavorite: store.removeFavorite,
      time,
      hideAvatars: computed(() => store.getOptions.value.hideAvatars),
      hideEicons: computed(() => store.getOptions.value.hideEicons),
      genderClass: normalizeGender(props.ad.characterGender),
      avatarUrl: getAvatarUrl(props.ad.characterName),
      profileUrl: getProfileUrl(props.ad.characterName),
      noInfo,
      nightmodeActive,
      attributes: computed(() => {
        return [
          props.ad.characterOrientation,
          props.ad.characterDomsub,
          props.ad.characterFurryPreference,
          ["adlist"].includes(props.mode) ? props.ad.channel : noInfo,
        ].filter((att) => att != noInfo);
      }),
    };
  },
});
