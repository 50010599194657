
import {computed, defineComponent, ref} from "vue";
import {useStore} from "../store/store";

export default defineComponent({
  name: "OptionsView",
  components: {},
  setup() {
    const store = useStore();
    const purgeIgnore = store.purgeIgnore;
    const limitIgnores = ref<boolean>(true);
    const hideavatars = computed({
      get: () => store.getOptions.value.hideAvatars,
      set: (value: boolean) => store.setHideAvatars(value),
    });
    const hideeicons = computed({
      get: () => store.getOptions.value.hideEicons,
      set: (value: boolean) => store.setHideEicons(value),
    });
    const timeOnly = computed({
      get: () => store.getOptions.value.timeOnly,
      set: (value: boolean) => store.setTimeOnly(value),
    });
    const filterDuplicates = computed({
      get: () => store.getOptions.value.filterDuplicates,
      set: (value: boolean) => store.setFilterDuplicates(value),
    });
    const nightMode = computed({
      get: () => store.getOptions.value.nightMode,
      set: (value: boolean) => store.setNightMode(value),
    });
    const showAllIgnores = function (){
      limitIgnores.value = false
    }
    const limitedIgnores = computed(
        () => {
          if (limitIgnores.value) {
            return Array.from(store.getOptions.value.ignoreSet).slice(0, 35)
          } else {
            return Array.from(store.getOptions.value.ignoreSet)
          }
        }
    )
    const options = store.getOptions
    return {
      hideeicons,
      hideavatars,
      limitIgnores,
      filterDuplicates,
      timeOnly,
      limitedIgnores,
      nightMode,
      options,
      showAllIgnores,
      fullIgnores: computed(() => store.getOptions.value.ignoreSet),
      removeIgnore: store.removeIgnore,
      purgeIgnore,
    };
  },
});
