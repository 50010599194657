
import {computed, defineComponent, ref} from "vue";
import useBreakpoints from "../ts/reactiveHelper";
import NavButton from "./NavButton.vue";

export default defineComponent({
  name: "Navbar",
  components: {NavButton},
  setup() {
    const sidebarActive = ref<boolean>(false);
    const reactive = useBreakpoints();
    const sidebarVisible = computed({
      get: () => sidebarActive.value && reactive.type.value === "mobile",
      set: (newVisibility: boolean) => {
        sidebarActive.value = newVisibility;
      },
    });
    return {
      sidebarActive,
      sidebarVisible,
      screenType: reactive.type,
    };
  },
});
