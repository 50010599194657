
import { defineComponent, PropType, computed } from "vue";
import { normalizeGender } from "../ts/genderHelper";
import { useStore } from "../store/store";

import { getProfileUrl, getAvatarUrl } from "../ts/urlHelper";
export default defineComponent({
  name: "CharacterSummary",
  props: {
    characterName: {
      type: String,
      required: true,
    },
    characterGender: {
      type: String,
      required: true,
    },
    attributes: {
      type: Object as PropType<Array<string>>,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const noInfo = "No Info";
    const store = useStore();

    return {
      noInfo,
      hideAvatars: computed(() => store.getOptions.value.hideAvatars),
      nightmodeActive: computed(() => store.getOptions.value.nightMode),
      genderClass: computed(() => normalizeGender(props.characterGender)),
      avatarUrl: computed(() => getAvatarUrl(props.characterName)),
      profileUrl: computed(() => getProfileUrl(props.characterName)),
    };
  },
});
