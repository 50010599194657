
import { defineComponent, PropType, ref } from "vue";
import { useStore } from "../store/store";
import { FilterSet } from "../store/state";
import FilterDialog from "./FilterDialog.vue";

export default defineComponent({
  name: "FilterCard",
  props: {
    filter: {
      type: Object as PropType<FilterSet>,
      required: true,
    },
  },
  components: {
    FilterDialog,
  },
  setup(props) {
    const store = useStore();
    const displayConfirmation = ref(false);
    const pause = store.pauseFilter;
    const filterDialogVisibility = ref(false);
    const options = store.getOptions
    return {
      options,
      deleteFilter: () => {
        store.deleteFilter(props.filter.id);
        displayConfirmation.value = false;
      },
      displayConfirmation,
      pause,
      filterDialogVisibility,
    };
  },
});
