
import {defineComponent, onBeforeMount} from "vue";
import NavBar from "./components/NavBar.vue";
import {useStore} from "./store/store";
import {FlistClient} from "@/ts/FlistClient";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
  },
  setup() {
    const store = useStore()
    onBeforeMount(async () => {
      const flistClient = new FlistClient()
      const flistMappingData = await flistClient.fetchFlistMappingData()
      store.setFlistMappingData(flistMappingData)
    })
    return {
      maxConnError: store.getMaxConnError,
      options: store.getOptions
    };
  },
});
