
import { defineComponent, ref } from "vue";
import FilterDialog from "./FilterDialog.vue";
import FilterCard from "./FilterCard.vue";
import { useStore } from "../store/store";
export default defineComponent({
  name: "FilterList",
  components: {
    FilterDialog,
    FilterCard,
  },
  setup() {
    const store = useStore();
    const filterDialogVisibility = ref(false);
    const options = store.getOptions
    return {
      options,
      filterDialogVisibility,
      getFilters: store.getFilters,
    };
  },
});
