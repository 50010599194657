
import {computed, defineComponent, ref} from "vue";
import {useStore} from "../store/store";
import Ad from "../components/Ad.vue";
import FilterList from "../components/FilterList.vue";

export default defineComponent({
  name: "AdView",
  components: {
    Ad,
    FilterList,
  },
  setup() {
    const op = ref();
    const toggle = (event: unknown) => {
      op.value.toggle(event);
    };
    const store = useStore();
    const isPaused = computed(() => store.getOptions.value.adListPaused);
    const pauseAdList = computed({
      get: () => store.getOptions.value.adListPaused,
      set: (value: boolean) => store.setPauseAdlist(value),
    });
    const toggleFresh = computed({
      get: () => store.getOptions.value.freshAdsOnly,
      set: (value: boolean) => store.setFreshAdsOnly(value),
    });
    const options = store.getOptions
    return {
      options,
      op,
      adListIsLoading: store.isAdListEmpty,
      getAds: store.getFilteredAds,
      sidebarvisible: false,
      pauseAdList,
      isPaused,
      toggle,
      toggleFresh,
    };
  },
});
