
import { defineComponent } from "vue";
import { useStore } from "../store/store";
import Status from "../components/Status.vue";
import FilterList from "../components/FilterList.vue";

export default defineComponent({
  name: "StatusView",
  components: {
    Status,
    FilterList,
  },
  setup() {
    const store = useStore();
    const options = store.getOptions
    return {
      options,
      statusListEmpty: store.isStatusListEmpty,
      getStatus: store.getFilteredStatus,
    };
  },
});
