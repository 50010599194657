import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa575b24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-list p-p-3 p-shadow-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterCard = _resolveComponent("FilterCard")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FilterDialog = _resolveComponent("FilterDialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.options.nightMode ? 'night':'day' ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFilters, (filter) => {
        return (_openBlock(), _createBlock(_component_FilterCard, {
          filter: filter,
          key: filter.id
        }, null, 8, ["filter"]))
      }), 128)),
      _createVNode(_component_Button, {
        icon: "pi pi-plus",
        class: "p-button-rounded p-button-raised",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterDialogVisibility = true))
      })
    ]),
    (_ctx.filterDialogVisibility)
      ? (_openBlock(), _createBlock(_component_FilterDialog, {
          key: 0,
          visibility: _ctx.filterDialogVisibility,
          "onUpdate:visibility": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterDialogVisibility) = $event)),
          filterUuid: ''
        }, null, 8, ["visibility"]))
      : _createCommentVNode("", true)
  ], 2))
}