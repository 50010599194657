
import {defineComponent} from "vue";

export default defineComponent({
  name: "NavButton",
  props: {
    displayText: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    }
  },
  components: {},
  setup() {
    return {};
  },
});
