
import {computed, defineComponent, PropType} from "vue";
import {Status} from "../store/state";
import {useStore} from "../store/store";
import {normalizeGender} from "../ts/genderHelper";
import {getAvatarUrl, getProfileUrl} from "../ts/urlHelper";
import {useToast} from "primevue/usetoast";

export default defineComponent({
  name: "Status",
  props: {
    ad: {
      type: Object as PropType<Status>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const noInfo = "No Info";
    const toast = useToast();
    return {
      pushIgnore: (charname: string) => {
        const detailMsg = charname + " was added to Ignores. See Options.";
        toast.add({
          severity: "info",
          summary: "Ignore added",
          detail: detailMsg,
          life: 3000,
        });
        store.pushIgnore(charname);
      },
      hideAvatars: computed(() => store.getOptions.value.hideAvatars),
      hideEicons: computed(() => store.getOptions.value.hideEicons),
      nightmodeActive: computed(() => store.getOptions.value.nightMode),
      genderClass: normalizeGender(props.ad.characterGender),
      avatarUrl: getAvatarUrl(props.ad.characterName),
      profileUrl: getProfileUrl(props.ad.characterName),
      noInfo,
      attributes: computed(() => {
        return [
          props.ad.characterOrientation,
          props.ad.characterDomsub,
          props.ad.characterFurryPreference,
        ].filter((att) => att != noInfo);
      }),
    };
  },
});
