
import {defineComponent, PropType, Slots} from "vue";
import {KinkFilter} from "@/store/state";
import {Kink} from "@/ts/common";

interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "KinkFilterSelect",
  props: {
    kinkFilter: {
      type: Object as PropType<KinkFilter>,
      required: true
    },
    kinks: {
      type: Object as PropType<Array<Kink>>,
      required: true
    },
  },
  components: {},
  setup(props, context: SetupContext) {
    const checkboxChange = (section: string) => {
      const id = props.kinkFilter.id
      context.emit("sectionToggle", {section, id});
    };
    const kinkListChange = (args: { value: string[] }) => {
      const id = props.kinkFilter.id
      const kinkIds = args.value
      context.emit("kinkListToggle", {kinkIds, id});
    };
    const deleteKinkFilter = () => {
      const id = props.kinkFilter.id
      context.emit("deleteKinkFilter", id)
    }
    return {
      checkboxChange,
      kinkListChange,
      deleteKinkFilter,
    };
  },
});
