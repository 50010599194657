
import { defineComponent } from "vue";
import { useStore } from "../store/store";
import Ad from "../components/Ad.vue";

export default defineComponent({
  name: "Favorites",
  components: {
    Ad,
  },
  setup() {
    const store = useStore();
    const options = store.getOptions
    return {
      faves: store.getFavorites,
      clearFavorites: store.clearFavorites,
      options,
    };
  },
});
