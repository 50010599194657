
import {computed, defineComponent, PropType, Slots} from "vue";
import {AdSearch} from "@/store/state";


interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "AdSearchLoadDialog",
  components: {},
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    savedAdSearches: {
      type: Object as PropType<AdSearch[]>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {

    const loadAd = (id: string) => {
      context.emit("load", id)
    }

    const deleteAd = (id: string) => {
      context.emit("delete", id)
    }

    const visibilityRef = computed({
      get: () => props.visibility,
      set: (newVisibility: boolean) => {
        context.emit("update:visibility", newVisibility);
      },
    });

    const cancel = () => {
      context.emit("update:visibility", false);
    };

    return {
      visibilityRef,
      cancel,
      loadAd,
      deleteAd
    };
  },
});
