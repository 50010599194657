import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53b75fbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar p-d-flex p-jc-between" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "p-d-flex p-px-3"
}
const _hoisted_4 = {
  key: 1,
  class: "burger p-d-flex p-ai-center p-pr-4"
}
const _hoisted_5 = { class: "sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NavButton = _resolveComponent("NavButton")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/",
        custom: ""
      }, {
        default: _withCtx(({navigate}) => [
          _createElementVNode("div", {
            onClick: navigate,
            class: "font-bowlby font-big logo p-d-flex p-ai-center p-pl-3 p-py-2"
          }, " FFA 2.0 ", 8, _hoisted_2)
        ]),
        _: 1
      }),
      (_ctx.screenType == 'screen')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: "/",
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive }) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'AdList'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/statuslist",
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive }) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'StatusList'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/adsearch",
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive }) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'AdSearch'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: {name:'charsummary'},
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive}) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'CharSummary'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/favorites",
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive }) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'Favorites'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/options",
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive }) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'Options'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/faq",
              custom: ""
            }, {
              default: _withCtx(({navigate, isActive }) => [
                _createVNode(_component_NavButton, {
                  onClick: navigate,
                  isActive: isActive,
                  displayText: 'FAQ'
                }, null, 8, ["onClick", "isActive"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("i", {
              class: "pi pi-bars",
              style: {"font-size":"2rem","cursor":"pointer"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sidebarActive = !_ctx.sidebarActive))
            })
          ]))
    ]),
    _createVNode(_component_Sidebar, {
      class: "sidebar",
      visible: _ctx.sidebarVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/",
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive }) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'AdList'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/statuslist",
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive }) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'StatusList'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/adsearch",
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive }) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'AdSearch'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: {name:'charsummary'},
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive}) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'CharSummary'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/favorites",
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive }) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'Favorites'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/options",
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive }) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'Options'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/faq",
            custom: ""
          }, {
            default: _withCtx(({navigate, isActive }) => [
              _createVNode(_component_NavButton, {
                onClick: navigate,
                isActive: isActive,
                displayText: 'FAQ'
              }, null, 8, ["onClick", "isActive"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}