
import {computed, defineComponent, PropType, Slots} from "vue";

interface SetupContext {
  slots: Slots;
  emit: (event: string, ...args: unknown[]) => void;
}

export default defineComponent({
  name: "FilterMultiselect",
  props: {
    selectedItems: {
      type: Object as PropType<Array<string>>,
      required: true,
    },
    availableOptions: {
      type: Object as PropType<Array<string>>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    nightMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  setup(props, context: SetupContext) {
    const items = computed({
      get: () => props.selectedItems,
      set: (selectedItems: Array<string>) => {
        context.emit("update:selecteditems", selectedItems);
        return true;
      },
    });
    const update = (update: Array<string>) => {
      context.emit("update:selecteditems", update);
    };
    return {
      items,
      update,
    };
  },
});
