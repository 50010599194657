
import {computed, defineComponent, ref} from "vue";
import {CHARACTER_SUMMARY, send} from "../ts/websocket";
import {useStore} from "../store/store";
import {useToast} from "primevue/usetoast";
import {v4 as uuidv4} from "uuid";
import CharacterSummary from "../components/CharacterSummary.vue";
import {normalizeGender} from "../ts/genderHelper";

class DisplayResult {
  date: Date;
  text: string;
  type: string;
  key: string;

  constructor(text: string, date: string, type: string, key: string) {
    this.text = text;
    this.date = new Date(date);
    this.type = type;
    this.key = key;
  }
}

export default defineComponent({
  name: "CharacterSummaryView",
  components: {CharacterSummary},
  props: {
    charname: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props) {
    const characterName = ref<string>(props.charname);
    const store = useStore();
    const currentCorrelationId = ref<string>(uuidv4());
    const searchRunning = ref<boolean>(false);
    const toast = useToast();
    const timeOnly = computed(() => store.getOptions.value.timeOnly);
    const nightmodeActive = computed(() => store.getOptions.value.nightMode);
    const calculateTime = (timestamp: string) => {
      const t = timestamp ? new Date(timestamp) : new Date();
      return timeOnly.value
          ? "[" + t.toLocaleTimeString() + "]"
          : "[" + t.toLocaleTimeString() + " - " + t.toLocaleDateString() + "]";
    };
    const isSearchRunning = computed((): boolean => {
      if (!searchRunning.value) {
        return false;
      } else {
        return (
            store.getCharacterSummary == null ||
            store.getCharacterSummary.value?.cid != currentCorrelationId.value
        );
      }
    });

    const fetchCharacterSummary = (charname: string) => {
      if (!charname) {
        toast.add({
          severity: "warn",
          summary: "Please enter a character Name",
          life: 2000,
        });
        return;
      }
      const requestId = uuidv4();
      currentCorrelationId.value = requestId;
      send(
          CHARACTER_SUMMARY,
          JSON.stringify({
            characterName: charname,
            cid: requestId,
          }),
          requestId
      );
      searchRunning.value = true;
      setTimeout(
          function (oldRequestId: string) {
            if (
                currentCorrelationId.value === oldRequestId &&
                isSearchRunning.value
            ) {
              toast.add({
                severity: "error",
                summary: "Error during Summary Fetch",
                detail:
                    "Sorry it took too long to collect the summary. Try again later :(",
                life: 5000,
              });
              searchRunning.value = false;
            }
          },
          4000,
          requestId
      );
    };
    const noInfo = "No Info";

    const combinedResult = computed((): Array<DisplayResult> => {
      if (store.getCharacterSummary != null) {
        let mappedAds = store.getCharacterSummary.value?.recentAds.map((a) => {
          return new DisplayResult(a.text, a.timestamp, "Ad", a.key);
        });
        if (mappedAds == undefined) {
          mappedAds = [];
        }
        let mappedStatus = store.getCharacterSummary.value?.recentLookings.map(
            (a) => {
              return new DisplayResult(
                  a.text,
                  a.timestamp,
                  "Status",
                  a.key
              );
            }
        );
        if (mappedStatus == undefined) {
          mappedStatus = [];
        }
        const combinedResults = [...mappedAds, ...mappedStatus];
        combinedResults.sort((a: DisplayResult, b: DisplayResult): number => {
          return b.date.getTime() - a.date.getTime();
        });
        return combinedResults;
      }
      return [];
    });

    if (props.charname) {
      fetchCharacterSummary(props.charname)
    }
    const options = store.getOptions
    return {
      options,
      combinedResult,
      genderClass: computed(() =>
          normalizeGender(store.getCharacterSummary.value?.characterGender)
      ),
      calculateTime,
      characterName,
      nightmodeActive,
      fetchCharacterSummary,
      characterSummary: store.getCharacterSummary,
      isSearchRunning,
      hideEicons: computed(() => store.getOptions.value.hideEicons),
      attributes: computed(() => {
        return [
          store.getCharacterSummary.value?.characterOrientation,
          store.getCharacterSummary.value?.characterDomsub,
          store.getCharacterSummary.value?.characterFurryPreference,
        ]
            .filter((att) => att != null)
            .filter((att) => att != noInfo);
      }),
    };
  },
});
