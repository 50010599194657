import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48f7233a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "p-d-flex p-jc-center p-pt-2"
}
const _hoisted_2 = { class: "statuslist" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterList = _resolveComponent("FilterList")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Status = _resolveComponent("Status")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FilterList),
    (_ctx.statusListEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressBar, {
            mode: "indeterminate",
            style: {"height":"0.5em","width":"18rem"}
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.statusListEmpty && _ctx.getStatus.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["p-d-flex p-jc-center p-pt-2", [_ctx.options.nightMode ? 'night-text':'day-text' ]])
        }, " Sorry there are no statuses matching your filters :( ", 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStatus, (ad) => {
        return (_openBlock(), _createBlock(_component_Status, {
          key: ad.key,
          ad: ad
        }, null, 8, ["ad"]))
      }), 128))
    ])
  ]))
}