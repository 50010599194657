
import { defineComponent } from "vue";
import {useStore} from "@/store/store";

export default defineComponent({
  name: "FaqView",
  components: {},
  setup() {
    const store = useStore()
    const options = store.getOptions
    return {options};
  },
});
